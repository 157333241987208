import { BaseTrack } from './types'
import { assign } from 'xstate'

export type Nodes = {
  vol: GainNode
  pan: PannerNode | StereoPannerNode
  eqHi: BiquadFilterNode // we connect on this one
  eqMd: BiquadFilterNode
  eqLo: BiquadFilterNode
  // compressor: DynamicsCompressorNode
  analyser: AnalyserNode
  master: GainNode
  broadcast: GainNode
}
export type NodesValues = {
  vol: number
  pan: number
  eqHi: number
  eqMd: number
  eqLo: number
  // compressor: CompressorArgs
  analyser: number
  master: boolean
  broadcast: boolean
}

export type NodesContext = NodesValues & {
  nodes: Nodes
}

const getPanner = (ctx: AudioContext): StereoPannerNode | PannerNode => {
  if (ctx.createStereoPanner) {
    return ctx.createStereoPanner() // pan
  }
  const panner = ctx.createPanner() // pan
  panner.panningModel = 'equalpower'
  return panner
}

// const defaultCompressor: CompressorArgs = {
//   threshold: -50,
//   knee: 40,
//   ratio: 12,
//   attack: 0,
//   release: 0.25
// }

export const createNodes = (
  ctx: AudioContext,
  masterOn: boolean,
  broadcastOn: boolean
): { nodes: Nodes; values: NodesValues } => {
  const vol = ctx.createGain()
  const pan = getPanner(ctx)
  const eqHi = ctx.createBiquadFilter()
  const eqMd = ctx.createBiquadFilter()
  const eqLo = ctx.createBiquadFilter()
  const analyser = ctx.createAnalyser()
  const master = ctx.createGain()
  const broadcast = ctx.createGain()

  // const compressor = ctx.createDynamicsCompressor()
  // compressor.threshold.setValueAtTime(
  //   defaultCompressor.threshold,
  //   ctx.currentTime
  // )
  // compressor.knee.setValueAtTime(defaultCompressor.knee, ctx.currentTime)
  // compressor.ratio.setValueAtTime(defaultCompressor.ratio, ctx.currentTime)
  // compressor.attack.setValueAtTime(defaultCompressor.attack, ctx.currentTime)
  // compressor.release.setValueAtTime(defaultCompressor.release, ctx.currentTime)

  eqLo.type = 'lowshelf'
  eqLo.frequency.value = 320.0
  eqLo.gain.value = 0 // The boost, in dB, to be applied; if negative, it will be an attenuation.

  eqMd.type = 'peaking'
  eqMd.frequency.value = 1000.0
  eqMd.Q.value = 0.5
  eqMd.gain.value = 0 // The boost, in dB, to be applied; if negative, it will be an attenuation.

  eqHi.type = 'highshelf'
  eqHi.frequency.value = 3200.0
  eqHi.gain.value = 0

  analyser.smoothingTimeConstant = 0.3
  analyser.fftSize = 1024

  vol.gain.value = 0
  master.gain.value = masterOn ? 1 : 0
  broadcast.gain.value = broadcastOn ? 1 : 0

  eqHi
    .connect(eqMd)
    .connect(eqLo)
    .connect(pan)
    .connect(vol)
    //.connect(compressor)
    .connect(analyser)

  analyser.connect(master)
  analyser.connect(broadcast)

  return {
    nodes: {
      vol,
      pan,
      eqHi,
      eqMd,
      eqLo,
      //compressor,
      analyser,
      master,
      broadcast
    },
    values: {
      vol: 0,
      pan: 0,
      eqHi: 0,
      eqMd: 0,
      eqLo: 0,
      //compressor: defaultCompressor,
      analyser: 0,
      master: masterOn,
      broadcast: broadcastOn
    }
  }
}

export const disconnectNodes = (nodes: Nodes) =>
  Object.values(nodes).forEach((n) => n.disconnect())

export const connectToNodes = (srcNode: AudioNode, nodes: Nodes) => {
  srcNode.connect(nodes.eqHi)
}
export const connectNodesToOut = (
  nodes: Nodes,
  masterGain: GainNode,
  broadcastGain: GainNode
) => {
  nodes.master.connect(masterGain)
  nodes.broadcast.connect(broadcastGain)
}
type Context = BaseTrack & NodesContext

export type NodesValEvent = {
  type: 'SET_VOL' | 'SET_PAN' | 'SET_EQHI' | 'SET_EQMD' | 'SET_EQLO'
  val: number
}
export type NodesToggleEvent = {
  type: 'TOGGLE_M' | 'TOGGLE_B' | 'TOGGLE_BIG'
}
export type NodesEvent = NodesValEvent | NodesToggleEvent

export const fadeOut = ({ context }: { context: Context }) => {
  context.nodes.vol.gain.setValueAtTime(
    context.nodes.vol.gain.value,
    context.ctx.currentTime
  )
  context.nodes.vol.gain.linearRampToValueAtTime(
    0,
    context.ctx.currentTime + 0.1
  )
}
export const selectMasterBroadcast = (mb: 'master' | 'broadcast') => ({ context }: { context: Context }) => {
  const n = context.nodes[mb]
  const v = context[mb]
  n.gain.setValueAtTime(
    n.gain.value,
    context.ctx.currentTime
  )
  n.gain.linearRampToValueAtTime(
    v ? 0 : 1,
    context.ctx.currentTime + 0.2
  )
  return !v
}
export const master = selectMasterBroadcast('master')
export const broadcast = selectMasterBroadcast('broadcast')

export const toggleMaster = assign({ master })

export const toggleBroadcast = assign({ broadcast })

export const toggleBig = (context: Context) => ({
  type: 'TOGGLE_BIG',
  id: context.id
})

export const setVolNode = (vol: GainNode, val: number, currentTime: number) => {
  vol.gain.setValueAtTime(
    vol.gain.value,
    currentTime
  )
  vol.gain.linearRampToValueAtTime(
    val,
    currentTime + 0.2
  )
  return val
}


export const vol = ({ context, event }: { context: Context, event: NodesValEvent }): number => {
  context.nodes.vol.gain.setValueAtTime(
    context.nodes.vol.gain.value,
    context.ctx.currentTime
  )
  context.nodes.vol.gain.linearRampToValueAtTime(
    event.val,
    context.ctx.currentTime + 0.2
  )
  return event.val
}

export const pan = ({ context, event }: { context: Context, event: NodesValEvent }): number => {
  if (event.val >= -1.0 && event.val <= 1) {
    if ((context.nodes.pan as PannerNode).panningModel) {
      const p = context.nodes.pan as PannerNode
      p.setPosition(event.val, 1, 1) // eslint-ignore-deprecated
    } else {
      (context.nodes.pan as StereoPannerNode).pan.linearRampToValueAtTime(
        event.val,
        context.ctx.currentTime + 0.1
      )
    }
    return event.val
  }
  return context.pan
}

const eq = (eqNode: 'eqHi' | 'eqMd' | 'eqLo') => ({ context, event }: { context: Context, event: NodesValEvent }): number => {
  const eqn = context.nodes[eqNode]
  if (event.val >= -10 && event.val <= 10) {
    eqn.gain.linearRampToValueAtTime(
      event.val,
      context.ctx.currentTime + 0.1
    )
    return event.val
  }
  return context[eqNode]
}
export const eqHi = eq('eqHi')
export const eqMd = eq('eqMd')
export const eqLo = eq('eqLo')

