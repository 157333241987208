import { FC, ReactNode, createContext, useEffect } from 'react'
import { MainMachine } from './state/main.machine'
import type { MainService } from './state/main.machine'
import { WebsocketMachine } from './state/websocket.machine'
import { RtcMachine } from './state/rtc.machine'
import { useActorRef, useSelector } from '@xstate/react'
import { ActorRefFrom } from 'xstate'
import { DownloadMachine } from './state/download.machine'

type GlobalType = {
  mainService: MainService
  wsService?: ActorRefFrom<typeof WebsocketMachine>
  dlService?: ActorRefFrom<typeof DownloadMachine>
  rtcService?: ActorRefFrom<typeof RtcMachine>

}
export const GlobalStateContext = createContext<GlobalType>({
  mainService: {} as MainService,
  wsService: {} as ActorRefFrom<typeof WebsocketMachine>,
  rtcService: {} as ActorRefFrom<typeof RtcMachine>
})

type GSP = {
  children: ReactNode
}
const safeParse = (ti: string | null) => {
  try {
    const m = JSON.parse(ti || "[]")
    return m
  } catch (e) {
    console.log('error parsing tracks info', e)
    return []
  }
}
export const GlobalStateProvider: FC<GSP> = ({ children }) => {
  const mainService = useActorRef(MainMachine)
  const wsService = useSelector(mainService, (snap) => snap.children.wsMachine)
  const dlService = useSelector(mainService, (snap) => snap.children.dlMachine)
  const rtcService = useSelector(wsService, (snap) => snap?.children.rtcMachine)

  useEffect(() => {
    const ti = window.localStorage.getItem('tracksInfo')
    const tracks = safeParse(ti)
    mainService.send({ type: 'LOAD_TRACKS', tracks })
  }, [mainService])
  return (
    <GlobalStateContext.Provider value={{ mainService, wsService, rtcService, dlService }}>
      {children}
    </GlobalStateContext.Provider>
  )
}
