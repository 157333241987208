import {
  setup,
  assign,
  fromPromise,
  sendParent,
  ActorRefFrom,
  assertEvent
} from 'xstate'

import {
  NodesContext,
  NodesEvent,
  vol,
  pan,
  eqHi,
  eqMd,
  eqLo,
  master,
  broadcast,
  toggleBig,
  disconnectNodes,
  fadeOut,
  createNodes,
  connectToNodes,
  connectNodesToOut
} from './nodes'

import {
  BaseTrack,
  AudioConstraints,
  ExtendedDeviceInfo
} from './types'

import { getAudioStream } from './utils'

export type MicContext = BaseTrack & NodesContext & {
  constraints?: AudioConstraints
  supportedConstraints?: MediaTrackSupportedConstraints
  deviceId?: string
  error?: string | Error
  devices: Map<string, ExtendedDeviceInfo>
  // mediaStream ?: MediaStream // don't need this if we have mediaStreamSource
  src?: MediaStreamAudioSourceNode // actx.createMediaStreamSource(stream)
}
type GetStream = {
  type: 'GET_STREAM',
  constraints?: AudioConstraints,
  deviceId?: string,
  mediaStream?: MediaStream
}

export type MicEvents = NodesEvent
  | { type: 'DONE' }
  | { type: 'RESUME' }
  | { type: 'xstate.init', deviceId?: string }
  | GetStream

type GetStreamInput = {
  input: {
    constraints?: AudioConstraints
    supportedConstraints?: MediaTrackSupportedConstraints
    deviceId?: string
    src?: MediaStreamAudioSourceNode // actx.createMediaStreamSource(stream)
  }
}
const getStream = fromPromise(({ input: { src, deviceId, constraints } }: GetStreamInput) => {
  console.log('getStream', src?.mediaStream, deviceId, constraints)
  if (src) {
    src.disconnect()
    // part of the firefox bug workaround
    src.mediaStream.getTracks().forEach(t => {
      t.stop()
      src.mediaStream.removeTrack(t)
    })
  }
  return getAudioStream({
    mediaStream: src?.mediaStream,
    deviceId,
    constraints,
  })
})

export const micMachine = setup({
  types: {
    context: {} as MicContext,
    events: {} as MicEvents
  },
  actors: {
    getStream
  },
  actions: {
    toggleBig: sendParent(({ context }) => toggleBig(context)),
    fadeOut,
    disconnect: ({ context }) => {
      console.log('disconnect')
      context.nodes.vol.gain.setValueAtTime(context.nodes.vol.gain.value, context.ctx.currentTime)
      if (context.src) {
        context.src.disconnect()
      }
      disconnectNodes(context.nodes)
    },
    loadingEnter: assign({
      deviceId: ({ context, event }) => {
        assertEvent(event, ['GET_STREAM', 'xstate.init'])
        context.nodes.vol.gain.setValueAtTime(context.nodes.vol.gain.value, context.ctx.currentTime)
        context.nodes.vol.gain.linearRampToValueAtTime(0, context.ctx.currentTime + 0.1)
        return event.deviceId || context.deviceId // at startup {type: 'xstate.init'} we get no deviceId or constraints
      }
    }),
    loadingExit: ({ context }) => {
      context.nodes.vol.gain.setValueAtTime(context.nodes.vol.gain.value, context.ctx.currentTime)
      context.nodes.vol.gain.linearRampToValueAtTime(context.vol, context.ctx.currentTime + 0.1)
    },
    sendTrackInfo: sendParent(({ context }) => {
      return ({
        type: 'SEND_TRACK_INFO',
        id: context.id,
        track: {
          id: context.id,
          kind: context.kind,
          deviceId: context.deviceId,
          vol: context.vol,
          playing: context.vol > 0.002
        }
      })
    })
  }
}).createMachine({
  id: 'micMachine',
  initial: 'loading',
  context: ({ input }) => {
    return input as MicContext
  },
  on: {
    RESUME: { actions: sendParent({ type: 'RESUME' }) },
    SET_VOL: { actions: [assign({ vol }), 'sendTrackInfo'] },
    SET_PAN: { actions: assign({ pan }) },
    SET_EQHI: { actions: assign({ eqHi }) },
    SET_EQMD: { actions: assign({ eqMd }) },
    SET_EQLO: { actions: assign({ eqLo }) },
    TOGGLE_M: { actions: assign({ master }) },
    TOGGLE_B: { actions: assign({ broadcast }) },
    TOGGLE_BIG: { actions: 'toggleBig' }

  },
  states: {
    loading: {
      entry: 'loadingEnter',
      exit: 'loadingExit',
      invoke: {
        src: 'getStream',
        id: 'getStream',
        input: ({ context, event }) => ({
          src: context.src,
          deviceId: event.type === "GET_STREAM" ? event.deviceId : context.deviceId,
          constraints: event.type === "GET_STREAM" ? event.constraints : context.constraints
        }),
        onDone: {
          target: 'running',
          actions: assign({
            devices: ({ event }) => event.output.devices,
            deviceId: ({ event }) => event.output.deviceId,
            constraints: ({ event }) => event.output.constraints,
            supportedConstraints: ({ event }) => event.output.supportedConstraints,
            src: ({ context, event }) => {
              const src = context.ctx.createMediaStreamSource(event.output.mediaStream)
              connectToNodes(src, context.nodes)
              return src
            }
          })
        },
        onError: {
          actions: assign({
            error: ({ event }) => event.error as Error
          }),
          target: 'running'
        }
      }
    },
    running: {
      entry: 'sendTrackInfo',
      on: {
        GET_STREAM: {
          target: 'loading'
        },
        DONE: {
          target: 'kill'
        }
      }
    },
    kill: {
      entry: 'fadeOut',
      exit: [
        'disconnect',
        sendParent(({ context }) => ({
          type: 'REMOVE_TRACK',
          id: context.id
        }))
      ],
      after: {
        120: {
          target: 'done'
        }
      }
    },
    done: {
      type: 'final'
    }
  }
})

export type MicService = ActorRefFrom<typeof micMachine>

export const createMicContext = (id: string, ctx: AudioContext, masterGain: GainNode, broadcastGain: GainNode, deviceId?: string): MicContext => {
  const { nodes, values } = createNodes(ctx, false, true)
  const devices = new Map()
  connectNodesToOut(nodes, masterGain, broadcastGain)
  return { id, kind: 'mic', ctx, deviceId, devices, nodes, ...values }
}
