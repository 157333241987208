import { FC, useContext } from 'react'
import { useSelector } from '@xstate/react'

import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable'

import { GlobalStateContext } from './globalState'
import { TrackMapItem } from './state/types'
import { PlayerB } from './player'
import { MicB } from './mic'

type TrackProps = {
  item?: TrackMapItem
  needsResume: boolean
  isSortingTracks: boolean
  className?: string
}

export const Track: FC<TrackProps> = ({
  item,
  needsResume,
  isSortingTracks
  // className,
}) => {
  return item?.kind === 'player'
    ? (
      <PlayerB item={item} isSortingTracks={isSortingTracks} />
    )
    : item?.kind === 'mic'
      ? (
        <MicB
          needsResume={needsResume}
          item={item}
        // dragControls={dragControls}
        />
      )
      : null
}

type TracksProps = {
  isSortingTracks: boolean
}

export const Tracks: FC<TracksProps> = ({
  isSortingTracks
}) => {
  const { mainService } = useContext(GlobalStateContext)

  const tracks = useSelector(mainService, (state) => state.context.tracks)
  const needsResume = useSelector(mainService, (state) => state.context.needsResume)
  const tracksOrder = useSelector(
    mainService,
    (state) => state.context.tracksOrder
  )
  /*
  const setTracksOrder = (tracksOrder: Array<string>) =>
    mainService.send({ type: 'REORDER_TRACKS', tracksOrder })
    */

  return (
    <SortableContext
      items={tracksOrder}
      strategy={horizontalListSortingStrategy}
    >
      {
        tracksOrder.map((id) => (
          <Track
            key={'track-' + id}
            needsResume={needsResume}
            item={tracks.get(id)}
            isSortingTracks={isSortingTracks}
          />
        ))
      }
    </SortableContext>
  )
}
