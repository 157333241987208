import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'

import {
  DraggableSyntheticListeners,
} from '@dnd-kit/core'

import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'

import { useSelector } from '@xstate/react'

import { GlobalStateContext } from './globalState'
import { PlaylistItemDial } from './speeddials'
import { PlayerService } from './state/player.machine'
import { PlaylistService } from './state/playlist.machine'

import { Media } from './state/database'
import { formatTime } from './faders'
import Icons from './icons'
import { PlayButton } from './buttons'

// import { RecordObject, RecService } from './state/record.machine'
// import { generateId } from './state/utils'

type ItemProps = {
  service: PlayerService
  media: Media
  listeners?: DraggableSyntheticListeners
  // disabled: boolean
}


export const Item: FC<ItemProps> = ({
  media,
  listeners,
  service
}) => {
  const { dlService } = useContext(GlobalStateContext)
  const downloads = useSelector(dlService, s => s?.context.downloads || new Map())
  const mainRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const isLooping = useSelector(service, s => s.context.loop)
  const toggleLoop = () => service.send({ type: 'TOGGLE_LOOP' })

  const playLabel = useSelector(service, (s) =>
    // @ts-ignore
    s.matches('ready.playing')
      ? 'pause'
      // @ts-ignore
      : s.matches('ready.idle')
        ? 'play'
        : '...'
  )

  const play = () => playLabel === 'pause'
    ? service.send({ type: 'PAUSE' })
    : service.send({ type: 'PLAY' })

  const stop = () => service.send({ type: 'STOP' })

  useEffect(() => {
    const dl = downloads.get(media.uri) as { contentLength: number, receivedLength: number }
    if (dl && dl.receivedLength && dl.contentLength && mainRef.current) {
      const w = mainRef.current.offsetWidth
      setWidth(20 + ((w - 20) * (dl.receivedLength / dl.contentLength)))
    } else {
      setWidth(0)
      // animate(width, 0, { ease: 'easeOut', duration: 0.01 })
    }
  }, [downloads, media.uri])

  return (
    <div
      className={"flex-grow rounded flex flex-row gap-2 p-2 place-items-center select-none relative transition-all duration-300 " + (media.isQueued ? "bg-accent h-16 pt-4 flex-none" : " bg-base-200 h-10 ")}
      ref={mainRef}
    >
      <PlaylistItemDial />
      <div className={
        media.isQueued
          ? "absolute w-full top-0 left-0 overflow-x-hidden text-xs bg-accent rounded truncate ps-2 "
          : "text-sm lg:text-base text-nowrap truncate w-32 flex-grow"}
      >{media.title}</div>
      {media.isQueued
        ? (
          <div className="flex flex-grow gap-1 lg:gap-2  " >
            <button className="btn btn-sm btn-circle" onClick={stop}><Icons.Stop /></button>
            <PlayButton className="btn btn-sm btn-circle" play={play} playLabel={playLabel} />
            <button className={"btn btn-sm btn-circle " + (isLooping ? "text-accent" : "")} onClick={toggleLoop}><Icons.Loop /></button>
          </div>
        )
        : null
      }

      <div className='text-xs lg:text-sm flex-none'>{formatTime(media.duration)}</div>
      {
        listeners
          ? (<button
            className="cursor-grab touch-none flex-none"
            {...listeners} ><Icons.DragHandle size="30px" /></button>)
          : <Icons.DragHandle size="30px" />
      }
      <div
        className='absolute animate-pulse h-3 rounded-b left-0 bottom-0 bg-green-400 transition-width duration-200 '
        style={{ width }}
      />
    </div>
  )
}


type SortableItemProps = {
  id: string
  idx: number
  service: PlayerService
  plService: PlaylistService
  media: Media
  isDragging?: boolean
  disabled: boolean
}

type SortableItemData = {
  trackId: string
}


const SortableItem: FC<SortableItemProps> = ({
  id,
  idx,
  isDragging = false,
  disabled,
  media,
  service,
  plService
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    data: {
      trackId: service.id
    } as SortableItemData
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    WebkitTapHighlightColor: 'transparent',
    transition
  }
  const onLoadIndex = () => plService?.send({ type: 'LOAD_IDX', idx })
  // const onDelete = (id: string) => plService?.send({ type: 'PLAYLIST_REMOVE', id })

  return (
    <div
      ref={disabled ? undefined : setNodeRef}
      style={style}
      className={'flex ' + (isDragging ? ' opacity-25 flex-none' : 'flex-none')}
      onClick={media.isQueued ? () => null : onLoadIndex}
      {...attributes}
    >
      <Item media={media} service={service} listeners={listeners} />
    </div >
  )
}

type PlaylistDnDProps = {
  service: PlayerService
  activeId: string
  isSortingTracks: boolean
}

export const PlaylistDnD: FC<PlaylistDnDProps> = ({
  service,
  activeId,
  isSortingTracks
}) => {
  const plService = useSelector(service, s => s.context.playlistRef)
  const playlist = useSelector(plService, s => s?.context.playlist)
  // const recService = useSelector(service, s => s.context.recService as RecService)
  // const orderPlaylist = (playlist: Playlist) => service.send({ type: 'PLAYLIST_ORDER', playlist })

  return (
    <div
      // className="space-y-1 select-none"
      className="flex flex-col w-full h-full gap-1 overflow-y-auto"
      style={{
        // necessary to prevent touch action from bubbling up to parent scrollable div
        // touchAction: activeId === null ? 'auto' : 'none'
      }}
    >

      <SortableContext
        items={playlist || []}
        strategy={verticalListSortingStrategy}
      >
        {
          (playlist || []).map((m, idx) => <SortableItem
            key={m.id}
            id={m.id}
            idx={idx}
            media={m}
            isDragging={m.id === activeId}
            service={service}
            plService={plService}
            disabled={isSortingTracks}
          />)
        }

      </SortableContext>
    </div>
  )
}

const PlaylistView = React.memo(PlaylistDnD)
export default PlaylistView
