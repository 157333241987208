import { FC, useState } from 'react'

import {
  DraggableSyntheticListeners,
} from '@dnd-kit/core'


import {
  AnimateLayoutChanges,
  useSortable,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable'


import { useSelector } from '@xstate/react'

import { VolFader, Speed, Pan, Eq } from './faders'
import { PlayMenu, TimeDial, PlaylistAddDial } from './speeddials'
import { TrackMapItem } from './state/types'
import { ToggleHeadset, ToggleBroadcast } from './buttons'
import { DragHandle, Levels, Clear, ChevronRight, ChevronLeft } from './icons'

import { PlayerService } from './state/player.machine'
import PlaylistView from './playlist'
import { TrackShell } from './shell'
import { formatTime } from './faders'

type TopBarProps = {
  big: boolean
  service: PlayerService
}
const TopBar: FC<TopBarProps> = ({
  big = true,
  service
}) => {
  const kill = () => service.send({ type: 'DONE' })
  const head = useSelector(service, (s) => s.context.master)
  const broad = useSelector(service, (s) => s.context.broadcast)

  const toggleHead = () => service.send({ type: 'TOGGLE_M' })
  const toggleBroadcast = () => service.send({ type: 'TOGGLE_B' })

  return (
    <>
      <ToggleHeadset checked={!head} onChange={toggleHead} />
      <ToggleBroadcast checked={!broad} onChange={toggleBroadcast} />
      {big ? (<>
        <div className='flex-grow' />
        <TimeDial service={service} />
      </>
      )
        : null
      }
      <div className='flex-grow' />
      <PlaylistAddDial onSearchModal={() => null} service={service} />
      <div className='flex-grow' />
      <button className=" p-1" onClick={kill}><Clear size='20px' /></button>
    </>
  )
}

type PlayerBodyProps = {
  big: boolean
  activeId: string
  onSearchModal: (o: boolean) => void
  service: PlayerService
  listeners: DraggableSyntheticListeners
  isSortingTracks: boolean
}
const PlayerBody: FC<PlayerBodyProps> = ({
  big = false,
  activeId,
  listeners,
  service,
  isSortingTracks
}) => {
  const [forward, setForward] = useState(true)
  const toggleBig = () => service.send({ type: 'TOGGLE_BIG' })
  const plService = useSelector(service, s => s.context.playlistRef)
  const trackTitle = useSelector(
    plService,
    (s) =>
      (s.context.playlist?.find((pi) => pi.isQueued) || { title: '' }).title
  )
  const duration = useSelector(service, (s) => s.context.duration || 0.001) // no division by zero
  const value = useSelector(
    service,
    (s) => s.context.mediaNode?.mediaElement?.currentTime || 0
  )


  return (
    <>
      <div
        className="flex-none text-xs text-nowrap w-full px-1 overflow-hidden"
        style={big ? {} : { width: '95%' }}
      >{trackTitle}</div>
      <div className='h-10 bg-base-300 flex-grow flex flex-row gap-1 rounded m-1 lg:m-2 p-1 lg:p-2 relative'>
        <div className="flex flex-col">
          <label className="h-8 swap swap-rotate" >
            <input type="checkbox" checked={big} onChange={toggleBig} />
            <ChevronLeft size='36px' className="swap-on" />
            <ChevronRight size='36px' className="swap-off" />
          </label>
          <div className="flex-grow min-h-4" />
          <VolFader service={service} />
        </div>

        <div className={'flex flex-col gap-2 w-full place-items-center flex-shrink '}>

          <div className={"flex  " + (big ? 'flex-row flex-none h-11 gap-2' : 'gap-2 ')}>
            <Pan key='pan' service={service} />
            <Speed key='speed' service={service} />
            {big ? (
              <>
                <div className='flex-grow min-w-4' />
                <Eq kind='lo' key='eqlo' service={service} />
                <Eq kind='md' key='eqmd' service={service} />
                <Eq kind='hi' key='eqhi' service={service} />
              </>
            )
              : null}
          </div>
          {big ? null : <div onClick={() => setForward(f => !f)} className='text-2xl my-3'>{forward ? formatTime(value) : formatTime(duration - value)}</div>}
          {big ? null : <PlayMenu service={service} />}
          {big
            ? null
            : (<button className="p-4"><Levels /></button>)
          }
          {big ? <PlaylistView service={service} activeId={activeId} isSortingTracks={isSortingTracks} /> : null}
        </div>
        <button
          className={"absolute -right-1 bottom-0 transition-all " + (big ? "opacity-0 scale-0" : "opacity-100 scale-100")}
          disabled={big}
          {...listeners}
        ><DragHandle size="32px" /></button>
      </div>
    </>
  )
}

type PlayerProps = {
  item: TrackMapItem
  isSortingTracks: boolean
}
/*
export const Player: FC<PlayerProps> = ({
  item,
  isSortingTracks
}) => {
  const modalRef = useRef<HTMLDialogElement>(null)
  const service = item.service as PlayerService
  // const analyser = useSelector(service, (s) => s.context.nodes.analyser)
  // const toggleBig = () => service.send({type: 'TOGGLE_BIG' })

  const onSearchModal = (o: boolean) => o
    ? modalRef.current?.showModal()
    : modalRef.current?.close()

  return (
    < div className={
      ' bg-base-100 carousel-item carousel-center flex flex-col flex-none rounded select-none ' +
      ' transition-all ease-in-out duration-300 ' +
      (item.big ? "w-full sm:w-1/2 xl:w-1/3 h-full " : "w-36 h-full")
    }
    >
      <div className='w-full h-8 flex flex-row gap-1'>
        <TopBar key="topbar" big={item.big} service={service} />
      </div>
      <PlayerBody key="body" big={item.big} onSearchModal={onSearchModal} service={service} isSortingTracks={isSortingTracks} />
      <dialog ref={modalRef} className="modal w-screen h-screen" >
        <div className="modal-box w-11/12 max-w-5xl h-full flex flex-col">
          <form method="dialog" className="bg-red-100 relative">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <div className='h-full w-full'>
            <h3 className="font-bold text-lg">Search</h3>
            ya</div>
        </div>
      </dialog>
    </div >
  )
}
*/

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })


export const PlayerB: FC<PlayerProps> = ({
  item,
  isSortingTracks
}) => {
  const {
    active,
    isDragging,
    listeners,
    setNodeRef,
    transition = '',
    transform,
  } = useSortable({
    id: item.service.id,
    data: {
      type: 'container'
    },
    animateLayoutChanges,
  })

  const [color, setColor] = useState<string>('bg-base-100')
  const service = item.service as PlayerService
  const plService = useSelector(service, s => s.context.playlistRef)

  const onDropFiles = (files: FileList | File[]) =>
    plService.send({ type: 'PLAYLIST_ADD_FILES', files })

  const onDropSignal = (over: boolean) =>
    over ? setColor('bg-accent') : setColor('bg-base-100')

  const playLabel = useSelector(service, (s) =>
    // @ts-ignore
    s.matches('ready.playing')
      ? 'pause'
      // @ts-ignore
      : s.matches('ready.idle')
        ? 'play'
        : '...'
  )
  const play = () =>
    playLabel === 'pause'
      ? service.send({ type: 'PAUSE' })
      : service.send({ type: 'PLAY' })
  // const esc = (event: React.KeyboardEvent<HTMLDivElement>) => event.key === 'Escape'
  // ? toggleBig(service.id)
  // : null

  return (
    <TrackShell
      item={item}
      color={color}
      onDropFiles={onDropFiles}
      onDropSignal={onDropSignal}
      transition={transition}
      transform={transform}
      setNodeRef={setNodeRef}
      isDragging={isDragging}
    >
      <div className='w-full h-8 flex flex-row gap-1'>
        <TopBar big={item.big} service={service} />
      </div>
      <PlayerBody
        big={item.big}
        activeId={active?.id as string}
        onSearchModal={() => null}
        service={service}
        listeners={listeners}
        isSortingTracks={isSortingTracks}
      />
    </TrackShell>
  )
}
