import { FC, useEffect, useRef } from 'react'

const meterStyle = {
  opacity: 0.2,
  transition: 'opacity 0.25s ease'
}
type MeterProps = {
  analyser: AnalyserNode
}

const Meter: FC<MeterProps> = ({
  analyser
}: MeterProps) => {
  const meterRef0 = useRef<HTMLDivElement>(null)
  const meterRef1 = useRef<HTMLDivElement>(null)
  const meterRef2 = useRef<HTMLDivElement>(null)
  const meterRef3 = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let running = true
    const array = new Uint8Array(analyser.fftSize) // cannot do getFloatTimeDomainData on Safari (Nov 2020)
    // https://gist.github.com/kevincennis/6149078
    const floatFromUint8 = (v: number): number => v / 0x80 - 1
    /*
    const rmsLevel = array => {
      const sum = array.reduce((acc, v) => acc + (floatFromUint8(v) ** 2), 0)
      return Math.sqrt(sum / array.length)
    }
    // Compute peak instantaneous power over the interval.
    const peakInstantaneousPowerDecibels = array => {
      const peakInstantaneousPower = array.reduce((peakInstantaneousPower, v) => Math.max(floatFromUint8(v) ** 2, peakInstantaneousPower), 0)
      return 10 * Math.log10(peakInstantaneousPower)
    }
    */

    // Compute average power over the interval.
    const avgPowerDecibels = (array: Uint8Array) => {
      const sumOfSquares = array.reduce((acc, v) => acc + (floatFromUint8(v) ** 2), 0)
      return 10 * Math.log10(sumOfSquares / array.length)
    }

    const animate = () => {
      if (running && meterRef0.current && meterRef1.current && meterRef2.current && meterRef3.current) {
        analyser.getByteTimeDomainData(array)
        const vol = avgPowerDecibels(array)
        meterRef0.current.style.opacity = vol > -48 ? "1" : "0.2"
        meterRef1.current.style.opacity = vol > -30 ? "1" : "0.2"
        meterRef2.current.style.opacity = vol > -20 ? "1" : "0.2"
        meterRef3.current.style.opacity = vol > -2 ? "1" : "0.2"
        window.requestAnimationFrame(animate)
      }
    }
    window.requestAnimationFrame(animate)
    return () => {
      running = false
    }
  }, [analyser]) // Make sure the effect runs only once
  return (
    <div className='flex flex-col w-6 space-y-1 '>
      <div className='w-6 h-2 bg-red-500' ref={meterRef3} style={meterStyle} />
      <div className='w-6 h-2 bg-green-500' ref={meterRef2} style={meterStyle} />
      <div className='w-6 h-2 bg-green-500' ref={meterRef1} style={meterStyle} />
      <div className='w-6 h-2 bg-green-500' ref={meterRef0} style={meterStyle} />
    </div>
  )
}

export default Meter
