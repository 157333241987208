import { FC, PointerEventHandler, useRef, useState, useEffect } from 'react'
import { scaleItInv, scaleIt, ScaleType } from './types'

const TWO_PI = Math.PI * 2
const TWOTHIRDS_2PI = Math.PI + (1 / 2 * Math.PI)
const DIAL_MIN = 0.1
const DIAL_MAX = 0.9
const DIAL_MIN_RAD = DIAL_MIN * TWO_PI
const DIAL_MAX_RAD = DIAL_MAX * TWO_PI

const transition = 'opacity 0.1s ease-in-out, transform 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out'
const setThetaFromValue = (min: number, max: number, scale: ScaleType, value: number): number => {
  // scale to [0,1]
  const tmp = scaleItInv(min, max, scale, value)
  // scale to [0.1,0.9]
  const v = scaleIt(DIAL_MIN, DIAL_MAX, ScaleType.Linear, tmp)
  // scale from [0.1, 0.9] to  [0, 2π]
  const t = v * TWO_PI
  // scale from  [0, 2π] to [-π, π]
  const ret = (t - TWOTHIRDS_2PI) % TWO_PI
  return ret
}

type RadialProps = {
  value: number
  resetValue?: number
  onChange: (val: number) => void
  label?: string
  min?: number
  max?: number
  formatFunc?: (v: number) => string
  scale?: ScaleType
}

export const Radial: FC<RadialProps> = ({
  onChange,
  label = '',
  value = 0,
  resetValue = 0,
  min = 0,
  max = 1.0,
  scale = ScaleType.Linear,
  formatFunc = (v: number) => v.toFixed(2),
}) => {
  const [open, setOpen] = useState(false)
  const [theta, setTheta] = useState(0)
  const trackRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const isDragging = useRef<boolean>(false)
  const isBlurring = useRef<boolean>(false)

  useEffect(() => {
    // we set this up in an effect so that we are not recalculating
    // the dial position on every value change that might be from the drag itself 
    if (isDragging.current === false) {
      const theta = setThetaFromValue(min, max, scale, value)
      setTheta(theta)
    }
  }, [value, min, max, scale])

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    isBlurring.current = true
    setOpen(false)
  }

  const handleDouble: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onChange(resetValue)
    const theta = setThetaFromValue(min, max, scale, resetValue)
    setTheta(theta)
  }

  const handleKeys: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const v = e.key === 'ArrowUp' || e.key === 'ArrowRight'
      ? value + 0.1
      : e.key === 'ArrowDown' || e.key === 'ArrowLeft'
        ? value - 0.1
        : value
    const theta = setThetaFromValue(min, max, scale, v)
    setTheta(theta)
    onChange(v)
  }
  const pointerUp: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDragging.current === false && isBlurring.current === false) {
      setOpen(true)
      thumbRef.current?.focus()
      return
    }
    isDragging.current = false
    thumbRef.current?.releasePointerCapture(e.pointerId)
  }

  const pointerDown: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    isBlurring.current = false
    thumbRef.current?.setPointerCapture(e.pointerId)
  }
  const pointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (
      trackRef.current &&
      thumbRef.current?.hasPointerCapture &&
      thumbRef.current.hasPointerCapture(e.pointerId)
    ) {
      // get theta
      isDragging.current = true
      const trackRect = trackRef.current?.getBoundingClientRect()
      const y = (e.clientY - (trackRect.y + (trackRect.height / 2)))
      const x = (e.clientX - (trackRect.x + (trackRect.width / 2)))
      const theta = Math.atan2(y, x)
      // scale from [-π, π] to  [0, 2π]
      let thetaScaled = (theta + TWOTHIRDS_2PI) % TWO_PI
      // cut the threshold on the dial left to 0.1 and on the right to 0.9
      if (thetaScaled <= DIAL_MIN_RAD || thetaScaled >= DIAL_MAX_RAD) {
        thetaScaled = thetaScaled <= DIAL_MIN_RAD
          ? DIAL_MIN_RAD
          : DIAL_MAX_RAD
        const value = scaleItInv(DIAL_MIN, DIAL_MAX, ScaleType.Linear, thetaScaled / TWO_PI)
        onChange(scaleIt(min, max, scale, value))
        // setTheta(setThetaFromValue(min, max, scale, value))
        return
      }
      const v = (thetaScaled / TWO_PI)
      // numbers now are [0.1,0.9], scale them to [0,1]
      const val = scaleItInv(DIAL_MIN, DIAL_MAX, ScaleType.Linear, v)

      onChange(scaleIt(min, max, scale, val))
      setTheta(theta)
    }

  }
  return (
    <div className="relative w-8 h-8 indicator" style={{ zIndex: (open ? 10 : 0) }}>
      <span className="absolute w-full left-0 -bottom-4 flex justify-center text-xs z-0">{label}</span>
      <div
        ref={trackRef}
        onClick={open ? () => setOpen(false) : () => null}
        className={"bg-base-200 border border-2 border-base-content absolute rounded-full transition-all duration-100  ease-in " + (open ? "drop-shadow-xl" : '')}
        style={
          (open
            ? { width: '6rem', height: '6rem', left: '-2rem', top: '-2rem' }
            : { width: '2rem', height: '2rem', left: '0rem', top: '0rem' }
          )
        }
      />

      <div
        tabIndex={0}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-label={label}
        onBlur={handleBlurEvent}
        className={"bg-base-200 absolute rounded-full cursor-pointer flex flex-row justify-end items-center touch-none " + (open ? "" : "border border-0.5 border-base-content")}
        ref={thumbRef}
        onKeyUp={handleKeys}
        onDoubleClick={handleDouble}
        onPointerDown={pointerDown}
        onPointerMove={pointerMove}
        onPointerUp={pointerUp}
        style={
          (open
            ? { transition, width: '2rem', height: '2rem', right: '0rem', rotate: theta + 'rad', transform: `translate(1.90rem, 0)` }
            : { transition, width: '2rem', height: '2rem', right: '0rem', rotate: theta + 'rad', transform: `translate(0, 0rem)` }
          )
        }
      ><div className="h-1 w-4 bg-base-content" /></div>

      <div
        className="absolute w-8 h-4 rounded left-0 -bottom-7 text-xs bg-base-200 flex justify-center select-none cursor-pointer transition-opacity"
        style={
          (open
            ? { opacity: 1 }
            : { opacity: 0 }
          )
        }
      >{formatFunc(value)}</div>
    </div>
  )
}

