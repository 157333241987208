import { FC, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { useSelector } from '@xstate/react'
import { Radial } from './radial'
import { scaleItInv, scaleIt, ScaleType } from './types'

import { PlayerService } from './state/player.machine'
import { MicService } from './state/mic.machine'

export type ServiceAny = MicService | PlayerService

import Meter from './meter'

type BaseFaderProps = {
  service: ServiceAny
}
export const volumeMax = 2

const log10 = (x: number) => Math.log(x) / Math.LN10
export const formatDb = (gainValue: number) => {
  const v = 20 * log10(gainValue)
  return v === -Infinity
    ? "-∞"
    : v.toFixed(1) + 'dB'
}

// peer id regex (!/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(props[propName])) {
const padit = (n: number) => String(n).padStart(2, '0')
const floorPad = (n: number) => padit(Math.floor(n))

export const formatTime = (pos?: number, subsec = false): string => pos === null || pos === undefined
  ? ''
  : isFinite(pos)

    ? pos > 60 * 60
      ? Math.floor(pos / (60 * 60)) + ':' + floorPad((pos / 60) % 60) + ':' + floorPad(pos % 60)
      : pos > 1
        ? Math.floor((pos / 60) % 60) + ':' + floorPad(pos % 60)
        : subsec ? pos.toPrecision(2) + ' sec' : '0:00'
    : 'live'


export const VolFader: FC<BaseFaderProps> = ({
  service
}) => {
  const analyser = useSelector(service, (s) => s.context.nodes.analyser)
  const scale = ScaleType.Log
  const boxRef = useRef<HTMLDivElement>(null)
  const val = useSelector(service, (s) => s.context.vol || 0)
  const setVal = (val: number) => service.send({ type: 'SET_VOL', val })

  const [h, setH] = useState(80)

  useEffect(() => {
    const parent = boxRef.current?.parentElement || document.createElement('div')
    const onResize = () => {
      setH(boxRef.current?.offsetHeight || 40)
    }
    window.addEventListener('resize', onResize)
    parent.addEventListener('transitionend', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      parent.removeEventListener('transitionend', onResize)
    }
  }, [])

  const setValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = scaleIt(0, volumeMax, scale, parseFloat(e.target.value))
    setVal(v)
  }

  return (
    <div className="w-8 h-full relative " ref={boxRef}>
      <input
        className="range range-lg -rotate-90 origin-left absolute -bottom-4 left-4 touch-none"
        style={{ width: h + 'px' }}
        type="range"
        min={0}
        step={0.01}
        max={1}
        value={scaleItInv(0, volumeMax, scale, val)}
        onChange={setValue}
      />
      <div
        className={"absolute h-4 w-8 rounded-b justify-center flex text-xs pointer-events-none " + ((val > 0.007) ? 'bottom-0 bg-base-100 ' : 'hidden')}
      > {formatDb(val)}
      </div>
      {/* allow pointer events to bubble through */}
      <div className={"absolute w-full left-1 pointer-events-none " + ((val > 0.02) ? 'bottom-5' : 'top-0 hidden')}>
        <Meter analyser={analyser} />
      </div>
    </div >
  )
}
export const PanFader = () => {
  const [val, setVal] = useState('0')
  const setValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value
    setVal(v)
  }
  const reset = () => setVal('0.5')

  return (
    <input
      className="fader fader-error touch-none [--range-shdw:rgba(0,0,0,0)]"
      type="range"
      min="0"
      step="0.01"
      max="1"
      value={val}
      onChange={setValue}
      // carefule on chrome, double click does NOT work when inspector is open !! (Sept. 2024)
      onDoubleClick={reset}
    />
  )
}

export const Pan: FC<BaseFaderProps> = ({
  service
}) => {
  const val = useSelector(service, (s) => s.context.pan || 0)
  const setVal = (val: number) => {
    service.send({ type: 'SET_PAN', val })
  }
  return (
    <Radial value={val} onChange={setVal} min={-1} max={1} resetValue={0} label="pan" />
  )
}

type EqKind = 'lo' | 'md' | 'hi'
type EqFaderProps = BaseFaderProps & {
  kind: EqKind
}

export const Eq: FC<EqFaderProps> = ({
  kind,
  service
}) => {
  const field = kind === 'lo' ? 'eqLo' : kind === 'md' ? 'eqMd' : 'eqHi'
  const type = kind === 'lo' ? 'SET_EQLO' : kind === 'md' ? 'SET_EQMD' : 'SET_EQHI'
  const val = useSelector(service, (s) => s.context[field])
  const onChange = (val: number) => service.send({ type, val })
  return (
    <Radial value={val} onChange={onChange} min={-10} max={10} resetValue={0} label={kind} />
  )
}

export const Speed: FC<{ service: PlayerService }> = ({
  service
}) => {
  const value = useSelector(
    service,
    (s) => s.context.mediaNode?.mediaElement?.playbackRate || 1
  )
  const onChange = (rate: number) => service.send({ type: 'SET_RATE', rate })
  return (
    <Radial value={value} onChange={onChange} min={0.25} max={4} scale={ScaleType.Speed} resetValue={1} label={'spd'} />
  )
}


export const Time: FC<{ service: PlayerService, disabled: boolean }> = ({
  service,
  disabled
}) => {
  const duration = useSelector(service, (s) => s.context.duration || 0.001) // no division by zero
  const value = useSelector(
    service,
    (s) => s.context.mediaNode?.mediaElement?.currentTime || 0
  )
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const time = parseFloat(e.target.value)
    if (isFinite(time)) {
      service.send({ type: 'SET_TIME', time })
    }
  }
  return (
    <input
      type="range"
      disabled={disabled || !isFinite(duration)}
      className="range range-lg touch-none [--range-shdw:rgba(0,0,0,0)]"
      min={0}
      max={duration}
      step={0.01}
      value={value}
      onChange={onChange}
    />

  )
}
